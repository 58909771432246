import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Link from "next/link";
import * as Router from "next/router";

import { Device } from "@/utils/device";
import { colours } from "@/utils";
import * as UserContext from "@/components/UserContext";
import * as I from "../../Icons";
import SubscribeForm from "@/components/marketing/SubscribeForm";

type StyleProps = {
  isLoggedIn?: boolean;
};

const noFooterPages = [
  "/digital/interests",
  "/digital/profile",
  "/digital/welcome",
  "/digital/pay",
  "/digital/select-plan",
  "/digital/seeking",
  "/digital/about",
  "/digital/complete",
  "/digital/pledge",
];

const groupedLinks = [
  {
    title: "Step Forward 2025",
    links: [
      {
        text: "Event",
        href: "/step-forward",
      },
      {
        text: "Tickets",
        href: "https://www.eventbrite.co.uk/e/allbrights-step-forward-summit-tickets-1098859521409?aff=oddtdtcreator",
        target: "_blank",
      },
    ],
  },
  {
    title: "Partner with us",
    links: [
      {
        text: "Find out more",
        href: "https://s3.eu-west-2.amazonaws.com/allbrightalliance.com/public/AllBright_Media_Kit_2023.pdf",
        target: "_blank",
      },
    ],
  },
  {
    title: "Learning",
    links: [
      {
        text: "Programmes",
        href: "/learning/programmes",
      },
      {
        text: "Courses",
        href: "/learning/courses",
      },
    ],
  },
  // {
  //   title: "Private hire",
  //   links: [
  //     {
  //       text: "Hire our spaces",
  //       href: "/private-hire",
  //     },
  //   ],
  // },
  {
    title: "About us",
    links: [
      {
        text: "Our mission",
        href: "/about",
      },
    ],
  },
  {
    title: "Careers",
    links: [
      {
        text: "Join us",
        href: "/careers",
      },
    ],
  },
  {
    title: "News and articles",
    links: [
      {
        text: "The AllBright Post",
        href: "/edit",
      },
    ],
  },
  {
    title: "Diversity and inclusion",
    links: [
      {
        text: "Vision",
        href: "/DEI",
      },
      {
        text: "Commitment",
        href: "/DEI",
      },
    ],
  },
  {
    title: "Support",
    links: [
      //TODO: OSH
      // {
      //   text: "Townhouse",
      //   href: "/support/townhouse",
      // },
      {
        text: "Virtual",
        href: "/support/virtual",
      },
      {
        text: "Learning",
        href: "/support/learning",
      },
      {
        text: "Contact us",
        href: "/support/contact-us",
      },
    ],
  },
];

const RxFooter: FunctionComponent = () => {
  const userInfo = React.useContext(UserContext.Context);
  const router = Router.useRouter();
  const isLoggedIn = !!userInfo.id || false;
  const hideFooter = noFooterPages.includes(router.pathname);

  if (hideFooter) return null;

  return (
    <S.Container isLoggedIn={isLoggedIn}>
      <S.IconContainer>
        <I.AllBright colour={colours.shell} />
      </S.IconContainer>
      <S.LinksContainer>
        {groupedLinks.map((item) => (
          <S.LinkListItem key={item.title}>
            <S.LinkTitle>{item.title}</S.LinkTitle>
            {item.links.map((link) => (
              <Link href={link.href} passHref key={link.text}>
                <S.Anchor target={link.target || "_self"}>{link.text}</S.Anchor>
              </Link>
            ))}
          </S.LinkListItem>
        ))}
      </S.LinksContainer>
      <S.TermsLink>
        <Link href="/policies/terms-and-conditions" passHref>
          <S.Anchor>Terms and privacy</S.Anchor>
        </Link>
      </S.TermsLink>
      {!isLoggedIn && <S.SubscribeForm />}
      <S.SocialLinks>
        <a
          href="https://twitter.com/weareallbright"
          target="_blank"
          rel="noopener noreferrer"
        >
          <I.Twitter colour={colours.shell} width={30} height={30} />
        </a>
        <a
          href="https://www.instagram.com/allbright"
          target="_blank"
          rel="noopener noreferrer"
        >
          <I.Instagram colour={colours.shell} width={30} height={30} />
        </a>
        <a
          href="https://www.facebook.com/weareAllBright"
          target="_blank"
          rel="noopener noreferrer"
        >
          <I.Facebook colour={colours.shell} width={30} height={30} />
        </a>
        <a
          href="https://www.linkedin.com/company/all-bright"
          target="_blank"
          rel="noopener noreferrer"
        >
          <I.LinkedIn colour={colours.shell} width={30} height={30} />
        </a>
      </S.SocialLinks>
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.footer<StyleProps>`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  background: ${colours.marketingGreenDark};
  color: ${colours.shell};
  padding: min(10%, 50px) 10%;
  display: grid;
  grid-template-areas:
    "icon"
    "links"
    "form"
    "terms"
    "social";
  gap: 25px;

  @media (min-width: ${Device.desktop}) {
    ${(p) =>
      !p.isLoggedIn
        ? `
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
      'links links icon'
      'links links form'
      '. . terms'
      '. . social';
    `
        : `
      grid-template-areas:
      'links links icon'
      'links links social'
      'links links terms'
      'links links .'
    `}
  }
`;

S.IconContainer = styled.div`
  grid-area: icon;
  width: fit-content;
  margin: 10px 0;

  @media (min-width: ${Device.tablet}) {
    margin: 10px 0 0 35px;
    transform: scale(1.5);
  }
`;

S.LinksContainer = styled.ul`
  grid-area: links;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;

  @media (min-width: ${Device.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${Device.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

S.LinkListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.LinkTitle = styled.h5`
  font-weight: 700;
  font-size: 1.2rem;

  @media (min-width: ${Device.tablet}) {
    font-size: 1.5rem;
  }
`;

S.Anchor = styled.a`
  width: fit-content;
  color: inherit;
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    color: ${colours.marketingOrange};
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 1.5rem;
  }
`;

S.SubscribeForm = styled(SubscribeForm)`
  grid-area: form;
`;

S.TermsLink = styled.div`
  grid-area: terms;
`;

S.SocialLinks = styled.div`
  grid-area: social;
  display: flex;
  gap: 20px;

  > * {
    cursor: pointer;
  }
`;

export default RxFooter;
