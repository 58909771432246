export const featuredItems = {
  accelerate: {
    copy: "For managers, early-stage founders or mid-level professionals, ready to transform and accelerate their career journey",
    href: "/accelerate",
    image: {
      alt: "",
      src: "/images/nav-features/accelerate-feature.png",
    },
  },
  connectWithUs: {
    copy: "Join us on LinkedIn for trending articles, updates from AllBright@ Old Sessions House, exciting announcements, member achievements, and much more.",
    href: "https://uk.linkedin.com/company/all-bright",
    image: {
      alt: "",
      src: "/images/nav-features/connect-with-us-feature.png",
    },
  },
  elevate: {
    copy: "For senior leaders, established founders or C-suite executives, ready to elevate their professional journey to the very top.",
    href: "/elevate",
    image: {
      alt: "",
      src: "/images/nav-features/elevate-feature.png",
    },
  },
  futureOfWork: {
    copy: "Gain valuable insights and strategies to attract, engage and retain top female talent for better business outcomes.",
    href: "/business#future-of-work",
    image: {
      alt: "",
      src: "/images/nav-features/future-of-work-feature.png",
    },
  },
  hostCorporateEvent: {
    copy: "Our Mayfair townhouse spans five floors, with a rooftop terrace, private dining rooms, penthouse and meeting spaces.",
    href: "/private-hire",
    image: {
      alt: "",
      src: "/images/nav-features/corporate-event-feature.png",
    },
  },
  hostSpecialEvent: {
    copy: "Our Mayfair townhouse spans five floors, with a rooftop terrace, private dining rooms, penthouse and meeting spaces.",
    href: "/spaces/townhouse#private-hire",
    image: {
      alt: "",
      src: "/images/nav-features/private-hire-feature.png",
    },
  },
  littleBrightBook: {
    copy: "This is your space to access curated perks, discounts and unmissable incentives – delivered straight from our favourite businesses to you.",
    href: "/groups/b17ef6d19c7a5b1ee83b907c595526dc",
    image: {
      alt: "",
      src: "/images/nav-features/bright-feature.png",
    },
  },
  marketplace: {
    copy: "Whether you're a freelancer, entrepreneur, or business owner, this group is designed to help you showcase your skills, promote your services, and discover exciting opportunities.",
    href: "/groups/f66db43a-6169-d0de-fe11-6e8020d8e67f",
    image: {
      alt: "",
      src: "/images/nav-features/marketplace-feature.png",
    },
  },
  meetOurCeo: {
    copy: '"Ambition is often misunderstood, and women can find themselves pulling away from it, fearing the societal expectations attached to it. But, we are here to change that narrative."',
    href: "/about#meet-our-ceo",
    image: {
      alt: "",
      src: "/images/nav-features/meet-our-ceo-feature.png",
    },
  },
  mentorMatching: {
    copy: "The mentorship tool for all AllBright members - where career-minded women benefit from sharing their skills, experience, and expertise with one another.",
    href: "/connect/mentorship",
    image: {
      alt: "",
      src: "/images/nav-features/mentorship-feature.png",
    },
  },
  persona: {
    copy: "Looking to advance your career but unsure where to start? Or already at the top and want to reach new heights? Take our quiz to find out how!",
    href: "https://www.allbrightcollective.com/quiz/personality",
    image: {
      alt: "",
      src: "/images/nav-features/persona-feature.png",
    },
  },
  readArticles: {
    copy: "From lessons on leadership and stepping forward in your career to knowing how to unplug and tips on your finances, we've got you covered at The AllBright Post. ",
    href: "/edit",
    image: {
      alt: "",
      src: "/images/nav-features/read-articles-feature.png",
    },
  },
  secureSponsorship: {
    copy: "Access resources you can share with your employer to help secure their sponsorship for your AllBright learning programme.",
    href: "/sponsorship/employee",
    image: {
      alt: "",
      src: "/images/nav-features/secure-sponsorship-feature.png",
    },
  },
  townhouse: {
    copy: "Take a virtual step into our five-storey central Mayfair home, and immerse yourself in the culture of AllBright.",
    href: "/spaces/townhouse#virtual-tour",
    image: {
      alt: "",
      src: "/images/nav-features/townhouse-feature.png",
    },
  },
};

export const NAV_LINKS_ANONYMOUS = [
  {
    title: "About Us",
    links: [
      {
        title: "Our Mission",
        path: "/about",
      },
      {
        title: "Diversity, Equity & Inclusion",
        path: "/DEI",
      },
      {
        title: "Careers",
        path: "/careers",
      },
    ],
    content: [featuredItems.meetOurCeo, featuredItems.connectWithUs],
  },
  {
    title: "For Businesses",
    links: [
      {
        title: "Our Alliance Programme",
        path: "/business",
      },
      {
        title: "Sponsor Your Team",
        path: "/sponsorship/employer",
      },
      {
        title: "Work With Us",
        path: "https://s3.eu-west-2.amazonaws.com/allbrightalliance.com/public/AllBright_Media_Kit_2023.pdf",
      },
    ],
    content: [featuredItems.futureOfWork],
  },
  {
    title: "For Individuals",
    links: [
      {
        title: "Learning Programmes",
        path: "/learning",
      },
      {
        title: "Events",
        path: "/events",
      },
      {
        title: "Employer Sponsorship",
        path: "/sponsorship/employee",
      },
    ],
    content: [featuredItems.persona, featuredItems.secureSponsorship],
  },
  {
    title: "Old Sessions House",
    links: [
      {
        title: "Old Sessions House",
        path: "/old-sessions-house",
      },
      {
        title: "Benefits",
        path: "/old-sessions-house?type=benefits",
      },
      {
        title: "Enquire",
        path: "/old-sessions-house?type=enquire",
      },
      {
        title: "Book a Tour",
        path: "/old-sessions-house?type=enquire",
      },
    ],
    content: [featuredItems.persona, featuredItems.readArticles],
  },
  {
    title: "Learning",
    links: [
      {
        title: "Accelerate",
        path: "/accelerate",
      },
      {
        title: "Elevate",
        path: "/elevate",
      },
      {
        title: "Elevate to the Board",
        path: "/elevate?type=board",
      },
    ],
    content: [featuredItems.accelerate, featuredItems.elevate],
  },
  {
    title: "Pricing",
    links: [
      {
        title: "Learning Programmes",
        path: "/memberships/all-access",
      },
      {
        title: "Old Sessions House",
        path: "/old-sessions-house",
      },
      {
        title: "Virtual Learning",
        path: "/memberships/virtual",
      },
      {
        title: "Business",
        path: "/memberships/business",
      },
    ],
    content: [featuredItems.secureSponsorship],
  },
  {
    title: "The AllBright Post",
    links: [
      {
        title: "The Money Agenda",
        path: "/edit/articles?topic=Money",
      },
      {
        title: "Live Well",
        path: "/edit/articles?topic=Health%20&%20Wellness",
      },
      {
        title: "Lead On",
        path: "/edit/articles?topic=Leadership",
      },
      {
        title: "Step Forward",
        path: "/edit/articles?topic=Work",
      },
    ],
    content: [featuredItems.readArticles],
  },
  {
    title: "Newsletter",
    links: [
      {
        title: "Sign-up to our Newsletter",
        path: "/newsletter",
      },
      {
        title: "Take the Quiz",
        path: "/quiz/personality",
      },
    ],
    content: [featuredItems.persona],
  },
];

export const NAV_LINKS_AUTHENTICATED = [
  {
    title: "Home",
    links: [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Recommendations",
        path: "/home#recommendations-section",
      },
      {
        title: "Community Feed",
        path: "/conversation",
      },
    ],
    content: [featuredItems.readArticles],
  },
  {
    title: "Connect",
    links: [
      {
        title: "Connections",
        path: "/connect",
      },
      {
        title: "Mentor Matching",
        path: "/connect/mentorship",
      },
      {
        title: "Your Mentorship",
        path: "/connect/your-mentorship",
      },
    ],
    content: [featuredItems.mentorMatching, featuredItems.connectWithUs],
  },
  {
    title: "Learning",
    links: [
      {
        title: "Academy Videos",
        path: "/academy",
      },
      {
        title: "My learning",
        path: "/academy/my-learning",
      },
      {
        title: "Accelerate",
        path: "/accelerate",
      },
      {
        title: "Elevate",
        path: "/elevate",
      },
      {
        title: "Elevate to the Board",
        path: "/elevate#elevate-board",
      },
    ],
    content: [featuredItems.accelerate, featuredItems.elevate],
  },
  {
    title: "Events",
    links: [
      {
        title: "All Events",
        path: "/events",
      },
      {
        title: "On demand",
        path: "/events/on-demand",
      },
      {
        title: "My events",
        path: "/events/my-events",
      },
    ],
    content: [],
    type: "events",
  },
  {
    title: "Groups",
    links: [
      {
        title: "My Groups",
        path: "/groups",
      },
      {
        title: "All Groups",
        path: "/groups#all",
      },
      {
        title: "AllBright Marketplace",
        path: "/groups/f66db43a-6169-d0de-fe11-6e8020d8e67f",
      },
    ],
    content: [featuredItems.marketplace, featuredItems.littleBrightBook],
  },
  {
    title: "The AllBright Post",
    links: [
      {
        title: "All Articles",
        path: "/edit",
      },
      {
        title: "The Money Agenda",
        path: "/edit/articles?topic=Money",
      },
      {
        title: "Live Well",
        path: "/edit/articles?topic=Health%20&%20Wellness",
      },
      {
        title: "Lead On",
        path: "/edit/articles?topic=Leadership",
      },
      {
        title: "Step Forward",
        path: "/edit/articles?topic=Work",
      },
    ],
    content: [],
    type: "articles",
  },
];

export const platformPages = [
  "home",
  "conversation",
  "connect",
  "academy",
  "events",
  "groups",
  "edit",
  "notifications",
  "messages",
  "account",
  "onboarding",
  "member",
  "/",
];

export const pagesWithTransparentNav = ["/club", "/onboarding"];
